/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.create-ticket-section {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.input-pane {
  padding: 12px 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  margin-bottom: 16px;
}
.input-pane > label {
  line-height: 32px;
  vertical-align: middle;
  margin-right: 2px;
  color: #1890ff;
}
